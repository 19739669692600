<template>
  <div>
    <vue-particles
      class="login-background"
      color="#dedede"
      :particleOpacity="0.7"
      :particlesNumber="60"
      shapeType="circle"
      :particleSize="4"
      linesColor="#dedede"
      :linesWidth="1"
      :lineLinked="true"
      :lineOpacity="0.5"
      :linesDistance="150"
      :moveSpeed="3"
      :hoverEffect="true"
      hoverMode="grab"
      :clickEffect="true"
      clickMode="push"
    ></vue-particles>

    <div class="login-other">
      <el-row :gutter="32">
        <el-col :span="12">
          <div class="grid-content" >
            <el-form
              :rules="rules"
              :label-position="labelPosition"
              label-width="64.38px"
              ref="loginForm"
              :model="loginForm"
              class="loginContainer"
            >
              <H3 class="loginTitle">慧云车务后台管理系统</H3>
              <el-form-item label="用户名" prop="username" style="white-space: nowrap;">
                <el-input
                  prefix-icon="el-icon-user-solid"
                  size="normal"
                  type="text"
                  v-model="loginForm.username"
                  auto-complete="off"
                  style="width: 280px"
                  placeholder="请输入用户名"
                ></el-input>
              </el-form-item>
              <el-form-item label="密码" prop="password">
                <el-input
                  prefix-icon="el-icon-lock"
                  size="normal"
                  type="password"
                  v-model="loginForm.password"
                  auto-complete="off"
                  style="width: 280px"
                  placeholder="请输入密码"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="submitForm('loginForm')">
                  登录
                </el-button>
                <el-button @click="resetForm('loginForm')">重置</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import qs from "qs";
import axios from "@/http/HTTP";
import { useUserStore } from "@/stores/user";
import { setToken } from "@/http/auth";
import { ElMessage } from "element-plus";
export default {
  name: "Login",
  data() {
    return {
      loginForm: {
        username: "admin",
        password: "",
        code: "",
        token: "", //绑定验证码的随机码
      },
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        code: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { min: 5, max: 5, message: "长度为 5 个字符", trigger: "blur" },
        ],
      },
      captchaImg: null,
      labelPosition: "left",
    };
  },
  methods: {
    enterFn() { 
      e.preventDefault(); // 阻止回车清空输入值
    },
    createUuid() { 
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
     },
    submitForm(formName) {
     // this.getCaptcha();
      let that = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          axios
            .post("/sys/user/curlogin?" + qs.stringify(this.loginForm))
            .then((res) => {
              console.log("res=",res)
              if (res.data == 1) {
                ElMessage({
                  showClose: true,
                  message: "用户登陆成功!",
                  type: "success",
                });
                const jwt = that.createUuid();
              console.log("jwt=",jwt)
              const store = useUserStore()
                store.$state.token = jwt;
                store.$state.curUserName = that.loginForm.username;

              sessionStorage.setItem("token",jwt)
              setToken(jwt);
              console.log(store.$state)
              this.$router.push("/backend");
                sessionStorage.setItem("isAdmin", true);
                sessionStorage.setItem("curUserName", formName.username);
              } else if (res.data == 0) { 
                ElMessage({
                  showClose: true,
                  message: "密码不正确!",
                  type: "fail",
                });
              }else if (res.data == 2) { 
                ElMessage({
                  showClose: true,
                  message: "用户不存在!",
                  type: "fail",
                });
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    getCaptcha() {
      axios.get("/captcha").then((res) => {
        this.loginForm.token = res.data.token;
        this.captchaImg = res.data.captchaImg;
        this.loginForm.code = "";
      });
    },
    appFunction(val) {
      const jwt1 = val;
      this.$store.commit("SET_TOKEN", jwt1);
    },
  },
  created() {
    //this.getCaptcha();
    localStorage.clear();
    let that = this;
    document.onkeydown = function (e) {
      e = window.event || e;
      if (
        that.$route.path == "/login" &&
        (e.code == "Enter" || e.code == "enter")
      ) {
        //验证在登录界面和按得键是回车键enter
        that.submitForm("loginForm"); //登录函数
      }
    };
  },
  mounted() {
    window.appFunction = this.appFunction;
    let ref = window.location.href;
    const jwt = ref.split("=")[1];
    if (jwt != null) {
      this.$store.commit("SET_TOKEN", jwt);
    }
  },
};
</script>

<style scoped>
.login-background {
  background: linear-gradient(-180deg, #9999ff 60%, #ffffff 100%);
  width: 100%;
  height: 100%; /**宽高100%是为了图片铺满屏幕 */
  z-index: 1;
  position: absolute;
}

.login-other {
  z-index: 1;
  margin: 240px 0 0 calc(calc(100vw - 770px) / 2);
  position: absolute;
}

.loginContainer {
  border-radius: 15px;
  background-clip: padding-box;
  width: 450px;
  padding: 15px 35px 15px 35px;
  background: #fefefe;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 25px #cac6c6;
}

.loginTitle {
  margin: 15px auto 20px auto;
  text-align: center;
  color: #707070;
}

.el-row {
  margin-bottom: 20px;
}

.el-row:last-child {
  margin-bottom: 0;
}

.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
</style>
